import React, { useEffect } from "react";
import "./style.scss";
import axios from "axios";
import prompts from "../prompts";

enum ChatBotRole {
  User = "user",
  System = "system",
}

interface ChatBotMessage {
  content: string;
  role: ChatBotRole;
}

const replaceURLsWithLinks = (text: string): string => {
  const urlRegex = /(\b(https?|ftp|file):\/\/[-A-Z0-9+&@#/%?=~_|!:,.;]*[-A-Z0-9+&@#/%=~_|])/ig;
  return text
    .replace('\n', '<br />')
    .replace(urlRegex, (url) => `<a href="${url}" target="_blank">${url}</a>`);
}

function ChatBot() {
  const urlParams = new URLSearchParams(window.location.search);
  const id = urlParams.get("id");

  const prompt = prompts.find((prompt) => prompt.id === id);
  const isChatOpen = true;
  const [message, setMessage] = React.useState("");
  const [messages, setMessages] = React.useState<ChatBotMessage[]>([
    {
      role: ChatBotRole.System,
      content: prompt?.message || "",
    },
  ]);
  const [isWaitingReply, setIsWaitingReply] = React.useState(false);

  const sendMessage = () => {
    setMessages((currentMessages) => [
      ...currentMessages,
      { content: message, role: ChatBotRole.User },
    ]);
    getReply(message);
    setMessage("");
  };

  const getReply = (message: string) => {
    axios
      .post(`https://inoveb.co.uk/chat/`, {
        token: "646fd6d8-0470-491a-b5a4-6325cd0c4f15",
        messages: messages,
        app: "empty",
        message,
      })
      .then((response) => {
        setIsWaitingReply(true);
        const responseMessage = response.data.message;
        const timeout = responseMessage.length * 40;
        console.log([
          responseMessage,
          responseMessage.length,
          timeout
        ])

        setTimeout(() => {
          setMessages((currentMessages) => [
            ...currentMessages,
            {
              content: responseMessage,
              role: ChatBotRole.System,
            },
          ]);
          setIsWaitingReply(false);
        }, timeout);
      });
  };

  useEffect(() => {
    const nestedElement = document.querySelector(".chat-bot-body");
    if (nestedElement) {
      nestedElement.scrollTo({
        top: nestedElement.scrollHeight,
        behavior: "smooth",
      });
    }
  }, [messages]);

  if (!prompt) {
    console.log("Prompt not found");
    return null;
  }

  const Avatar = () => {
    return (
        <img style={{borderRadius: '50%'}} src='https://imgix.ranker.com/user_node_img/3707/74120030/original/74120030-photo-u-1238119745?auto=format&q=60&fit=crop&fm=pjpg&dpr=2&w=650' alt='avatar' height='42' width='42' />
    )
  }

  const filteredMessages = messages.slice(1);

  return (
    <div className="ChatBot">
      <div className={`chat-bot-container ${isChatOpen ? "chat-bot-container__is-open" : ""}`}>
        <div className="chat-bot-header">
          <strong>{prompt.contact} @ {prompt.company}</strong>
          {prompt.project}
        </div>
        <div className="chat-bot-body">
          <div className="chat-bot-messages" style={{paddingBottom: 144, top: 52}}>
            {filteredMessages.map((message, index) => (<>
              <div
                key={index}
                className={`chat-bot-message ${message.role === ChatBotRole.System ? "chat-bot-message__is-bot" : "chat-bot-message__is-user"}`}>
                <div
                  className="chat-bot-message__content"
                  dangerouslySetInnerHTML={{ __html: replaceURLsWithLinks(message.content) }}
                >
                </div>
              </div>
                  {message.role === ChatBotRole.System &&
                <><Avatar /></>
                  }
                </>
            ))}
            {isWaitingReply && (
              <div className="chat-bot-message chat-bot-message__is-bot typing">
                <div className="chat-bot-message__content">Vivianna is typing...</div>
              </div>
            )}
          </div>
        </div>
        <div className="chat-bot-footer">
          <textarea
            className="chat-bot-footer__input"
            placeholder="Type something...."
            value={message}
            onChange={(e) => setMessage(e.target.value)}
            onKeyDown={(e) => {
              if (e.key === "Enter" && !e.shiftKey) {
                e.preventDefault();
                sendMessage();
              }
            }}
          />
          <button
            type="button"
            onClick={sendMessage}
            className="chat-bot-footer__send-button">
            Send
          </button>
        </div>
      </div>
    </div>
  );
}

export default ChatBot;
